import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/StephanVolklandANYME/Desktop/gitProjects/customerDocumentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "abstractintroduction"
    }}>{`Abstract/Introduction`}</h1>
    <p>{`This is the documentation section of the AnyMessage Email API's. `}</p>
    <p>{`If you want to send SMS via the E-mail to SMS API, please note that your account needs to be specifically configured in order to send SMS via the E-mail Gateway. Unauthorized accounts will be blocked/dropped from the Gateway. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      